import React, {useState} from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import {Container, Navbar, Nav} from 'react-bootstrap';
import '../styles/nav.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const NavMain = () => {
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY >= 20) {
                setNavbar(true);
            } else {
                setNavbar(false);
            }
        }
    };
    
    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', changeBackground)
    }

    // const logoB = getImage(data.file.)

    const data = useStaticQuery(graphql`
        query  {
           LogoB: file(relativePath: {eq: "CodevLogo150x144.webp"}) {
            childImageSharp {
                gatsbyImageData (
                    width: 50
                    placeholder: BLURRED
                    layout: FIXED
                )
            }
            }
            LogoW: file(relativePath: {eq: "CodevLogoWhite150x144.webp"}) {
                childImageSharp {
                    gatsbyImageData (
                        width: 50
                        placeholder: BLURRED
                        layout: FIXED
                    )
                }
                }
        }
    `)

    const logoB = getImage(data.LogoB.childImageSharp.gatsbyImageData)
    const logoW = getImage(data.LogoW.childImageSharp.gatsbyImageData)

    return(
    <Navbar expand="lg" collapseOnSelect sticky="top" className={navbar ? 'navbar active nav' : 'navbar nav'}>
        <Container className="px-5">
        <Navbar.Brand href="/">
            <GatsbyImage 
                image={navbar ? logoB : logoW}
                ClassName="nav-logo"
                placeholder="blurred"
                loading="eager"
                width={50}
                alt="codev media home page button"
            />
            {/* <img src={navbar ? `${Logo}` : `${LogoW}`} className="nav-logo" alt="codev media home page button" height="48" width="50"/>             */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className={navbar ? 'responsive-navbar-nav active' : 'responsive-navbar-nav'}/>
        <Navbar.Collapse id="responsive-navbar-nav" className={navbar ? 'responsive-navbar-nav active' : 'responsive-navbar-nav'}>
            <Nav className="ml-auto navw px-2">
                <Link to="/" className={navbar ? 'text-black nav-link' : 'text-white nav-link'}>Home</Link>
                <Link to="/about-us/" className={navbar ? 'text-black nav-link' : 'text-white nav-link'}>About Us</Link>
                <Link to="/services/" className={navbar ? 'text-black nav-link' : 'text-white nav-link'}>Services</Link>
                <Link to="/faq/" className={navbar ? 'text-black nav-link' : 'text-white nav-link'}>FAQ</Link>
                <Link to="/blog/" className={navbar ? 'text-black nav-link' : 'text-white nav-link'}>Blog</Link>
                <Link to="/contact-us/" className={navbar ? 'text-black nav-link' : 'text-white nav-link'}>Contact</Link>
            </Nav>
        </Navbar.Collapse>
        </Container>
    </Navbar>
    )
}

export default NavMain;