import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import { Link } from 'gatsby';
import {Container, Row, Col} from "react-bootstrap";
import "../styles/footer.css";
import "animate.css";
import {FaFacebookSquare, FaInstagramSquare, FaTwitterSquare, FaYoutubeSquare} from "react-icons/fa"

const Footer = () => {
    return (
        <footer className="footer-section py-4">
            < Container fluid className="mx-2">
                <Row className="justify-content-center mx-3">
                    <Col xs={12} md={3}>
                        <Row className="footer-logo">
                            <a href="/">
                                <StaticImage
                                    src="../images/CodevLogoWhite150x144.webp" 
                                    // fluid={data.file.childImageSharp.fluid} 
                                    className="logo mb-3"
                                    layout="constrained"
                                    alt='codev media logo link to home page'
                                />
                            </a>
                        </Row>
                        <Row className="codev-desc">
                            <p className="light-text">
                                We offer full-scale digital marketing solutions for bold business owners ready to scale their brands and remix their web designs. Codev Media is a search engine optimization agency in Oklahoma where innovation meets expertise.
                            </p>
                        </Row>
                        <Row className="footer-social">
                            <a href="https://www.facebook.com/CodevMediaOKC" target="_blank" aria-label="facebook" rel="noreferrer" className="tapTarget"><FaFacebookSquare className="social-icons" alt="codev media Facebook page button" /></a>
                            <a href="https://twitter.com/codevmediaokc" target="_blank" aria-label="twitter" rel="noreferrer" className="tapTarget"><FaTwitterSquare className="social-icons" alt="codev media Twitter page button" /></a>
                            <a href="https://www.instagram.com/codevmedia/" target="_blank" aria-label="instagram" rel="noreferrer" className="tapTarget"><FaInstagramSquare className="social-icons" alt="codev media Instagram page button" /></a>
                            <a href="https://www.youtube.com/channel/UC1I0hmrtcbZ3E77F9Vva6Ww" target="_blank" aria-label="youtube" rel="noreferrer" className="tapTarget"><FaYoutubeSquare className="social-icons" alt="codev media Youtube page button" /></a>
                        </Row>
                    </Col>
                    <Col xs={12} md={3} className="mt-4">
                        <Row className="justify-content-center">
                            <p className="footer-heading">Privacy & TOS</p> 
                        </Row>
                        <Row className="justify-content-center">
                            <Link to="/privacy-policy/" className="light-text tapTarget">Privacy Policy</Link>
                        </Row>
                        <Row className="justify-content-center">
                            <Link  to="/terms-of-service/" className="light-text tapTarget">Terms of Service</Link>
                        </Row>
                    </Col>
                    <Col xs={12} md={3} className="mt-4">
                        <Row className="justify-content-center">
                            <p className="footer-heading">Navigate</p> 
                        </Row>
                        <Row className="justify-content-center">
                            <Link to="/" className="light-text tapTarget">Home</Link>
                        </Row>
                        <Row className="justify-content-center">
                            <Link  to="/about-us/" className="light-text tapTarget">About Us</Link>
                        </Row>
                        <Row className="justify-content-center">
                            <Link  to="/services/" className="light-text tapTarget">Services</Link>
                        </Row>
                        <Row className="justify-content-center">
                            <Link  to="/faq/" className="light-text tapTarget">FAQ</Link>
                        </Row>
                        <Row className="justify-content-center">
                            <Link  to="/blog/" className="light-text tapTarget">Blog</Link>
                        </Row>
                        <Row className="justify-content-center">
                            <Link  to="/contact-us/" className="light-text tapTarget">Contact Us</Link>
                        </Row>
                    </Col>
                    <Col xs={12} md={3} className=" mt-4">
                        <Row className="justify-content-center">
                            <p className="footer-heading">Contact Us</p> 
                        </Row>
                        <Row className="justify-content-center mb-2">
                            <a href="mailto: info@codevmedia.com" className="light-text">Info@CodevMedia.com</a>
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-center light-text text-center">
                    <div>
                        © {new Date().getFullYear()} By 
                        {" "}
                        <Link to="/" className="light-text">Codev Media</Link>
                        . All Rights Reserved.
                    </div>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;